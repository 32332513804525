import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"


import { cn } from "../../lib/utils"


const buttonVariants = cva(
 "inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
 {
   variants: {
     variant: {
       default: "bg-primary text-primary-foreground hover:bg-primary/90",
       destructive:
         "bg-destructive text-destructive-foreground hover:bg-destructive/90",
       outline:
         "border border-2 border-input rounded-3xl text-input roboto-condensed bg-background pointer-events-none",
       secondary:
         "bg-secondary text-secondary-foreground hover:bg-secondary/80",
       ghost: "hover:bg-accent hover:text-accent-foreground",
       link: "text-primary underline-offset-4 hover:underline",
       },
     size: {
       default: "h-9 py-2 px-4 lg:px-6 xs:text-xs sm:text-sm md:text-md 2xl:text-lg",
       sm: "h-7 lg:h-10 md:h-12 rounded-md px-3",
       lg: "h-11 rounded-md px-8",
       icon: "h-10 w-10",
     },
   },
   defaultVariants: {
     variant: "default",
     size: "default",
   },
 }
)


export interface ButtonProps
 extends React.ButtonHTMLAttributes<HTMLButtonElement>,
   VariantProps<typeof buttonVariants> {
 asChild?: boolean
}


const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
 ({ className, variant, size, asChild = false, ...props }, ref) => {
   const Comp = asChild ? Slot : "button"
   return (
     <Comp
       className={cn(buttonVariants({ variant, size, className }))}
       ref={ref}
       {...props}
     />
   )
 }
)
Button.displayName = "Button"


export { Button, buttonVariants }
